import React from "react";
import Logo from "./components/Logo";
import Mains from "./components/Mains";
import Extras from "./components/Extras";
import Total from "./components/Total";
import { Provider } from "./Context";
import packageInfo from './data.json';
import cube_logo from "./components/cube-logo.png"
import portapolis_logo from "./components/portapolis.png";
import "./styles.css";

export default function App() {
  return (
    <Provider>
      <div className="menu">
        <Logo />
        <Mains type="Πρωινό" meals={packageInfo.breakfast} />
        <aside className="aside">
          <Extras type="Ροφήματα" items={packageInfo.drinks} />
          <Extras type="Γλυκά" items={packageInfo.sweets} />
        </aside>
      </div> 
        <div className="underline_end"></div>
          <div className="copyrights">
            <a href="https://www.cube-creations.com" target="_blank" rel="noreferrer">
              <img src={cube_logo} alt="cube-logo" className="cube-logo"/>
            </a>
              <h5>© 2024 Cube Creations. All rights reserved.</h5>
          </div> 
    </Provider>
    
  );
}